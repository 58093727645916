<template>
    <div class="page">
        <div v-for="(item,index) in viewList" :key="index" @click="openRoute(item.url)">{{item.name}}</div>

    </div>
</template>

<script>
export default ({
    data() {
        return {
            viewList:[{url:"/monitoring-detail",name:"站点监控"},{url:"/monitoring-detail",name:"站点监控2"},{url:"/monitoring-detail",name:"站点监控3"}]
        }
    },
    methods: {
        openRoute(url) {
            this.$router.push(url);
        }
    }
})
</script>

<style lang="scss" scoped>
.page {
    display: flex;
    flex-direction: row;

    div {
        text-align: center;
        width: 180px;
        height: 60px;
        line-height: 60px;
        background: #eee;
        margin: 10px;
        cursor: pointer;
    }
}
</style>
